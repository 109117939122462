<template>
  <div>
    <v-container fluid>
      <v-card flat class="transparent">
        <v-card-text class="text-center">

            <img :src="require('@/assets/404.svg')" alt="" width="450">

          <h1 class="my-4">
            OOPS Page non trouvée !
          </h1>
          <p>
            Nous n'avons pas trouvé la page que vous recherchez
          </p>
          <v-btn color="primary"  depressed to="/">Accueil</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>


export default {}
</script>

<style scoped>

</style>